.social {
  z-index: 3;
  width: 100%;
  opacity: 0.7;
  padding: 3px;
  box-sizing: border-box;
}

.social:hover {
  opacity: 1;
}

.social__ico {
  width: 100%
}

@media screen and (max-width: 500px) {
  .social {
    padding: 0;
  }
}