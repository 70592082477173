.footer {
  background-color: #8884;
   margin: 0;
  margin-top: auto;
  padding: 0 15px;
  box-sizing: border-box;
  width: 100%;
  height: fit-content;
}

.footer .text {
  margin: 0;
  padding: 5px;
  flex-direction: row;
  justify-content: space-between;
  background-color: transparent;
  color: #ddd;
}

@media screen and (max-width: 640px) {

}
