.portfolio {
  margin: 0;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.portfolio__nav {
  box-sizing: border-box;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

@media screen and (max-width: 1400px) {
  .portfolio__nav {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1000px) {
  .portfolio__nav {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }
}

@media screen and (max-width: 500px) {
  .portfolio {
    padding: 5px;
  }

  .portfolio__nav {
    grid-template-columns: 1fr;
    gap: 5px;
  }
}