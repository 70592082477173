
.header {
  margin: 0 0 10px;
  padding: 40px 20px 20px;
  padding-left: calc(20px + (20vw - 200px));
  padding-right: calc(20px + (20vw - 200px));
  box-sizing: border-box;
  width: 100%;
  background-color: #8884;
  border-bottom: #8886 solid 2px;
  color: #ccc;
  display: grid;
  grid-template-columns: 200px 1fr auto;
  grid-template-rows: auto auto 1fr;
  grid-template-areas: 
  "pic capt aside"
  "pic main aside"
  "pic adds aside";
  gap: 2px 2vw;
  position: relative;
}

.header .background {
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0px;
  left: 0px;
}

.header .text{
  background-color: transparent;
}

.header div:first-child {
  grid-area: pic;
  align-self: center;
}

.header div:nth-child(2) {
  grid-area: capt;
}

.header div:nth-child(3) {
  grid-area: main;
}

.header div:nth-child(4) {
  grid-area: adds;
}

.header div:nth-child(5) {
  grid-area: aside;
}

.header__me {
  display: flex;
  flex-direction: column;
  background-color: #8882;
  height: 100%;
  justify-content: space-between;
}

.header__photo {
  width: 200px;
  max-width: 100%;
  height: auto;
}

.header__socials {
  display: grid;
  grid-template-columns: repeat(6, auto);
  gap: 0;
  padding: 3px 0 0;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
}

.photo .text {
  background-color: transparent;
}

.header .text {
  padding-top: 0;
  padding-bottom: 0;
}

.header .text:first-of-type {
  height: 100%;
  align-items: end;
}

@media screen and (min-width: 1400px) {
  .header {
    padding: 40px 100px 20px;
  }
}

@media screen and (max-width: 1000px) {
  .header {
    padding: 12px;
    grid-template-columns: 200px auto;
    grid-template-areas:
      "pic capt"
      "pic main"
      "pic adds"
      "aside aside";
    gap: 2px 2vw;
  }
}

@media screen and (max-width: 500px) {
  .header {
    padding: 5px;
    grid-template-columns: 30% auto;
    grid-template-areas:
      "pic capt"
      "pic main"
      "adds adds"
      "aside aside";
    gap: 2vw 2px;
  }

  .header .text__text {
    margin: 3px;
  }
}

