.card {
  box-sizing: border-box;
  max-width: 100%;
  background-color: var(--inner);
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  position: relative;
}

.card__pic {
  width: 100%;
  aspect-ratio: 4/3;
  object-fit: fill;
  border: transparent 3px solid;
  box-sizing: border-box;
}

.card__heading {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 5px;
  justify-content: space-between;
  align-items: flex-start;
}

.card__heading .text {
  background-color: inherit;
  text-align: center;
  padding-top: 0;
  padding-bottom: 0;
}

.card .text {
  flex-grow: 0;
}

.card .text__text {
  height: 100%;
}

.card__link {
  color: unset;
  text-decoration: unset;
}