.button {
  width: fit-content;
  height: 36px;
  min-width: 36px;
  box-sizing: border-box;
  border: var(--line) double 3px;
  background-color: inherit;
  color: inherit;
  margin: 0;
  padding: 5px;
  font-weight: 700;
  font-size: 18px;
  text-decoration: none;
  z-index: 3;
  text-align: center;
}

.button:hover {
  background-color: var(--line);
  color: var(--inner);
}

.button:disabled {
  opacity: 0.2;
  background-color: var(--inner);
  color: var(--line);
}

.button__ghost {
  margin: 0;
  padding: 5px;
  background-color: transparent;
  border: none;
  font: inherit;
  color: inherit;
}

@media screen and (max-width: 1000px) {
  .button {
    font-size: calc(18px - 0.7*(10px - 1vw));
    margin: 5px;
  }
}
