.background {
  width: 100%;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  z-index: 1;
}

.background__container {
  position: absolute;
}

.background__shape {
  opacity: 0.1;
  width: 8vmax;
  height: 8vmax;
  border: var(--borcol) 5px solid;
}

.background__shape_0 {
  animation: spin ease alternate infinite both, fade 10s ease alternate infinite;
}

.background__shape_1 {
  border-radius: 50%;
  animation: shrink ease-in-out alternate infinite both, fade 7s ease alternate infinite;
}

.background__shape_2 {
  width: 0;
  height: 0;
  border-top: 0;
  border-left: 5vmax solid transparent;
  border-right: 5vmax solid transparent;
  border-bottom: 7vmax solid var(--borcol);
  animation:  fade ease alternate infinite both, shrink 10s ease alternate infinite, spin 15s infinite both;
}

.background__container:nth-of-type(6n+1) {
  --borcol: #800;
}

.background__container:nth-of-type(6n+2) {
  --borcol: #880;
}

.background__container:nth-of-type(6n+3) {
  --borcol: #080;
}

.background__container:nth-of-type(6n+4) {
  --borcol: #088;
}

.background__container:nth-of-type(6n+5) {
  --borcol: #008;
}

.background__container:nth-of-type(6n) {
  --borcol: #808;
}

@keyframes spin {
  from {
    transform: rotate(-60deg);
  } to {
    transform: rotate(300deg);
  }
}

@keyframes shrink {
  from {
    transform: scale(1);
  } to {
    transform: scale(3);
  }
}

@keyframes fade {
  from {
    opacity: 0.2;
  } to {
    opacity: 0;
  }
}


