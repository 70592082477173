.popup {
  margin: 0;
  padding: 0;
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 1;
  z-index: 5;
}

.popup_hide {
  display: none;
}

.popup__overlay {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  z-index: 5;
  background-color: #0008;
}

.popup__body {
  background-color: #888c;
  margin: 0;
  padding: 0;
  position: absolute;
  opacity: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: space-between;
  align-items: center;
  text-align: center;
  z-index: 5;
}

.popup__heading {
  margin: 0;
  padding: 10px;
  box-sizing: border-box;
  border: #888 solid 2px;
  width: 100%;
  background-image: linear-gradient(90deg, #400, #440, #040, #044, #004, #404);
  color: #aaa;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 21px;
  position: relative;
}

.popup__inner {
  margin: 0;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

.popup__buttons {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
}

.popup .button {
  border: #888 double 3px;
  opacity: 0.75;
  z-index: 5;
}

.popup .button:hover {
  opacity: 1;
}

.popup__buttons .button {
  width: 50px;
  margin: 0 20px;
  color: #888;
}

.popup__buttons .button:first-of-type {
  background-image: linear-gradient(90deg, #400, #440, #040);
}
.popup__buttons .button:last-of-type {
  background-image: linear-gradient(90deg, #044,#004,#404);
}

.popup__caption {
  margin: 0;
  padding: 5px 10px;
  color: inherit;
  flex-grow: 1;
}