@font-face {
  font-family: 'Exo2';
  font-style:  normal;
  font-weight: 100;
  font-display: swap;
  src: url("Exo2-Thin.ttf") format("truetype");
}

@font-face {
  font-family: 'Exo2';
  font-style:  italic;
  font-weight: 100;
  font-display: swap;
  src: url("Exo2-ThinItalic.ttf") format("truetype");
}

@font-face {
  font-family: 'Exo2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("Exo2-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Exo2';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("Exo2-Italic.ttf") format("truetype");
}

@font-face {
  font-family: 'Exo2';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("Exo2-Bold.ttf") format("truetype");
}

@font-face {
  font-family: 'Exo2';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("Exo2-BoldItalic.ttf") format("truetype");
}