.game {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  justify-content: space-around;
  justify-items: center;
  align-content: center;
}

.game .text {
  background-color: transparent;
}

.game__board {
  width: 500px;
  height: 400px;
  max-width: 100%;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(10, 1fr);
  align-self: center;
  position: relative;
}

.game__over {
  background-color: #888;
  border: #fff 3px double;
  position: absolute;
  opacity: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 10px;
}

.game__over .text__text:first-of-type {
  padding-top: 10px;
  text-decoration: underline;
}

.game__score {
  display: flex;
  flex-direction: column;
  width: auto;
  text-align: center;
  align-items: center;
  padding: 0 0 0px;
}

.game__block {
  width: 100%;
  height: 100%;
  border: #888 2px outset;
  opacity: 0.9;
}

.game .button {
  --line: #ddd;
  --inner: #444;
}

.game__block:disabled {
  opacity: 0.4;
}

.game__block_0 {
  background-color: #800;
  background-image: linear-gradient(135deg, #c22, #800);
}

.game__block_1 {
  background-color: #880;
  background-image: linear-gradient(135deg, #cc2, #880);
}

.game__block_2 {
  background-color: #080;
  background-image: linear-gradient(135deg, #2c2, #080);
}

.game__block_3 {
  background-color: #088;
  background-image: linear-gradient(135deg, #2cc, #088);
}

.game__block_4 {
  background-color: #008;
  background-image: linear-gradient(135deg, #22c, #008);
}

.game__block_5 {
  background-color: #808;
  background-image: linear-gradient(135deg, #c2c, #808);
}

@media screen and (max-width: 1000px) {
  .game {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: dense;
  }

  .game__board {
    grid-column: 1/3;
    width: calc(500px - (200px - 20vw));
  }

  .game__score {
    flex-direction: row;
    align-items: center;
  }

  .game__score:first-of-type {
    flex-direction: row-reverse;
  }
}

@media screen and (max-width: 500px) {
  .game {
    display: flex;
    flex-direction: column;
  }

  .game__board {
    width: calc(400px - (50px - 10vw)*5);
    height: 80vw;
  }

  .game__score {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }

  .game__score:first-of-type {
    flex-direction: row;
  }
}