.text {
  color: inherit;
  margin: 0;
  padding: 10px 10px 15px;
  background-color: #88888828;
  font-family: "Exo2";
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 18px;
  z-index: 2;
}

.text_nopad {
  padding-bottom: 0;
  padding-top: 0;
}

.text__caption {
  margin: 0;
  padding: 10px 0 5px;
  font-weight: 700;
  font-size: 20px;
}

.text__text {
  margin: 10px 0;
  padding: 0;
  font-weight: 400;
  justify-self: auto;
}

.text__techs {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.text__tech {
  margin: 5px;
  padding: 2px;
  border: var(--line) 1px solid;
}

.text__link {
  margin: 5px 0 0;
  padding: 0px;
  font-weight: 400;
  font-style: italic;
  color: inherit;
  opacity: 0.7;
}

.text__link:hover {
  opacity: 1;
}

.text__intro {
  padding-bottom: 0;
  margin-bottom: -10px;
}

.text__span:nth-of-type(6n+1) {
  color: #c22;
}

.text__span:nth-of-type(6n+2) {
  color: #cc2;
}

.text__span:nth-of-type(6n+3) {
  color: #2c2;
}

.text__span:nth-of-type(6n+4) {
  color: #2cc;
}

.text__span:nth-of-type(6n+5) {
  color: #57f;
}

.text__span:nth-of-type(6n) {
  color: #c2c;
}

@media screen and (max-width: 1000px){
  .text {
    font-size: calc(18px - (10px - 1vw)/1.3);
  }
  .text__caption {
    font-size: calc(20px - (10px - 1vw)/1.3);
  }
}