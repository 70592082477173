.section {
  margin: 10px 0;
  padding: 0;
  box-sizing: border-box;
  border-bottom: #8886 solid 2px;
  width: 100%;
  background-color: #8884;
  color: #ddd;
  --inner: #600a;
  --line: #800
}

.section__heading {
  margin: 0;
  padding: 10px;
  box-sizing: border-box;
  border: var(--line) solid 2px;
  width: 100%;
  background-color: var(--inner);
  color: #aaa;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 21px;
  position: relative;
}

.section__heading .background{
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0px;
  left: 0px;
}

.section__heading .background__container{
  --borcol: #888;
  transform: scale(0.5);
}

.section__block {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.section__block_hide {
  display: none;
}

.section .text {
  padding-left: 3.2%;
}

.section:nth-of-type(6n+2){
  --inner:#660a;
  --line: #880;
}

.section:nth-of-type(6n+3) {
  --inner: #060a;
  --line: #080;
}

.section:nth-of-type(6n+4) {
  --inner: #066a;
  --line: #088;
}

.section:nth-of-type(6n+5) {
  --inner: #008a;
  --line: #22c;
}

.section:nth-of-type(6n) {
  --inner: #606a;
  --line: #808;
}
.section__caption {
  margin: 0;
  padding: 5px 10px;
  padding-left: 5vw;
  color: inherit;
  flex-grow: 1;
  z-index: 2;
}

.section .text__tech {
  margin: 5px;
  padding: 5px;
  border: #888 1px solid;
}

@media screen and (max-width: 1000px) {
  .section__heading {
    font-size: calc(21px - (10px - 1vw));
  }

  .section .button {
    height: fit-content;
    min-height: calc(36px - (10px - 1vw)*1.3);
    min-width: calc(36px - (10px - 1vw)*1.3);
  }
}