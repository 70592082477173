@import url('../../../vendor/normalize.css');

.app {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-color: #000;
  font-family: 'Exo2', 'Arial Bold', sans-serif;
  line-height: 1.15;
  position: relative;
}