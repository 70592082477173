.main {
  display: flex;
  position: relative;
  flex-direction: column;
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  width: 95%;
  max-width: 1400px;
  min-height: 100vh;
  align-items: center;
  justify-content: flex-start;
  z-index: 2;
}