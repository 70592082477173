.navigation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.navigation .button {
  background-color: var(--inner);
  width: 100%;
  padding: 0;
}

.navigation .button:hover {
  background-color: var(--line);
  color: #ddd;
}

.navigation .button:nth-child(6n + 1) {
  --line: #800;
  --inner: #6004;
}

.navigation .button:nth-child(6n + 2) {
  --line: #880;
  --inner: #6604;
}

.navigation .button:nth-child(6n + 3) {
  --line: #080;
  --inner: #0604;
}

.navigation .button:nth-child(6n + 4) {
  --line: #088;
  --inner: #0664;
}

.navigation .button:nth-child(6n + 5) {
  --line: #22c;
  --inner: #0084;
}

.navigation .button:nth-child(6n) {
  --line: #808;
  --inner: #6064;
}


@media screen and (max-width: 1000px) {
  .navigation {
    padding-top: 1vw;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  .navigation .button {
    width: fit-content;
  }
}

@media screen and (max-width: 500px) {
  .navigation {
    padding-top: 0;
  }
}